.c-product-box {
  padding: 10px 0;
  box-sizing: border-box;
  min-height: 350px;
  &__image {
    padding: 10px;
    img {
      width: 100%;
    }
  }
  &__content {
    h4 {
      text-align: center;
      font-weight: normal;
      margin-top: 10px;
      margin-bottom: 0;

      a {
        color: #007864;
      }
    }
    p {
      text-align: center;
    }
  }
}