// Vars
$carousel-height: 600px;
$carousel-mobile-height: 580px;

// Breakpoints
$tablet-breakpoint: 768px;
$ipad-breakpoint: 992px;

// Colors
$color-blue: #007864;
$color-black: #000;
$color-white: #fff;
$color-grey: #AFAFAF;
$color-light-grey: #dcdcdc;

// Z index
$z-index-news: 5;