.cp-checkout {
  padding: 50px 0;

  // General checkout styles
  table, button, input, select, a, label, p {
    font-size: 14px !important;
  }

  // Shipping and payment methods styles
  #sylius-shipping-methods, #sylius-payment-methods {
    .description {
      margin-top: 0;
    }
    label {
      margin-bottom: 0;
    }
    .fee {
      font-size: 14px;
    }
  }

  // Addresses style
  #sylius-shipping-address, #sylius-billing-address {
    .header {
      font-size: 18px !important;
    }
    address {
      font-size: 14px;
    }
  }

  // Title
  h3.ui.header {
    font-size: 18px !important;
  }

  // Semantic ui grid fix
  .grid {
    margin-top: 0 !important;
  }

  // Thank you text
  .thank-you-text {
    display: block !important;
    margin-bottom: 20px !important;

    #sylius-thank-you {
      .sub {
        font-size: 14px !important;
      }
    }
  }

  // Pay link
  #sylius-pay-link {
    margin-top: 20px;
  }
}