.cp-cart {
  padding: 50px 0;

  table, button {
    font-size: 14px !important;
  }

  #sylius-cart-update {
    margin-top: 20px;
  }
}