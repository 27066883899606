.cp-product {
  padding: 50px 0;

  .ui.segment {
    font-size: 14px;
  }

  // Product reviews
  &__reviews {
    // Link font size
    a {
      font-size: 14px;
    }

    // Icon size
    i {
      font-size: 14px;
    }
  }

  // Product description
  &__description {
    p {
      font-size: 14px;
    }
  }

  // Product variants
  &__variants {
    label, select, input, table {
      font-size: 14px !important;
    }
  }

  // Product tabs
  &__tabs {
    margin-top: 20px;
    a, .tab {
      font-size: 14px !important;
    }
  }

  #downloads {
    ul{
      padding: 0;
    }
    li {
      padding: 6px;
      list-style-type: none;
      background-color: #333333;
      border-top: 1px solid #ffffff;
      color: #ffffff;
      a {
        color: #ffffff;
        margin-left: 10px;
      }
      a:hover {
        color: #ffffff;
      }
    }
  }


  table {
    border-collapse: collapse;
    width: 100%;

    td, th {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tr:nth-child(even){background-color: #f2f2f2;}

    tr:hover {background-color: #ddd;}

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #434443;
      color: white;
    }
  }

  .associated {

    h2 {
      font-size: 15px;
      margin-bottom: 0;
      margin-top: 30px;
    }

    hr {
      margin: 1px 0 15px 0;
    }

  }

}

