@import "common/variables";
@import "common/mixins";

.cp-product-listing {
  // Taxon details
  &__taxon-details {
      padding: 50px 0;

    // Image
    &__image {
      img {
        width: 100%;
      }
    }

    // Content
    &__content {
      h1 {
        text-transform: uppercase;
        font-weight: bold;
        @include respondBetween(0, $ipad-breakpoint) {
          margin-top: 20px;
        }
      }
    }
  }

  &__list {
    margin-bottom: 20px;
    &__header {
      background-color: $color-blue;
      padding: 10px 15px;
      h3 {
        margin: 0;
        color: white;
        font-weight: normal;
      }
    }
    &__products {
      .col-md-3 {
        border: 1px solid grey;
        box-sizing: border-box;

        &:first-child {
          border-right: none;
        }

        &:nth-child(2n+0) {
          border-right: none;
        }

        &:nth-child(3n+0) {
          border-right: none;
        }

        &:nth-child(3n+1) {
          border-right: none;
        }

        &:nth-child(5n+0) {
          border-right: none;
        }

        &:nth-child(n+5) {
          border-top: none;
        }

        &:nth-child(4n+0) {
          border-right: 1px solid grey;
          @include respondBetween(0, $ipad-breakpoint) {
            border: none;
          }
        }

        &:last-child {
          border-right: 1px solid grey;
          @include respondBetween(0, $ipad-breakpoint) {
            border: none;
          }
        }

        @include respondBetween(0, $ipad-breakpoint) {
          border: none;
        }

        @include respondBetween(0, $tablet-breakpoint) {
          width: 100%;
        }
      }
    }
  }
}

.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}