.c-page-hero {
  height: 500px;
  background-image: url('../img/demo-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: table;
  width: 100%;
  background-position: center;

  // Content
  &__content {
    display: table-cell;
    vertical-align: middle;

    // Title
    h2 {
      color: white;
      margin-bottom: 20px;
    }

    // Subtitle
    h5 {
      color: white;
      font-size: 19px;
      font-weight: 100;
      line-height: 1.7em;
    }

    // Container align
    .container {
      display: block;
      margin: 0 auto;
    }
  }
}