.c-downloads {
  &__table {
    table {
      width: 100%;
      th {
        background-color: #007864;
        color: white;
      }
      td, th {
        padding: 10px;
      }
      tr {
        border-bottom: 1px solid grey;
      }
    }
    &__taxon {
      background-color: #007864;
      td {
        color: white;
      }
    }
  }

  &__item {
    color: black;
    margin-right: 5px;
  }

  &__mobile-taxon {
    background-color: #007864;
    padding: 10px;
    color: #ffffff;
  }


  &__mobile-product {
    padding: 10px;
    cursor: pointer;
  }

  &__hide-for-desktop {
    display: block;

    #downloads {
      ul{
        padding: 0;
      }
      li {
        padding: 6px;
        list-style-type: none;
        background-color: #007864;
        border-top: 1px solid #ffffff;
        color: #ffffff;
        a {
          color: #ffffff;
          margin-left: 10px;
        }
        a:hover {
          color: #ffffff;
        }
      }
    }

  }
  &__hide-for-mobile {
    display: none;
  }
  @media (min-width: 1480px){
    &__hide-for-desktop {
      display: none;
    }
    &__hide-for-mobile {
      display: block;
    }
  }

}