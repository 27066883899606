.c-page{
  margin: 20px 0px;

  // Header
  &__header {
    background-color: #007864;
    padding: 10px 20px;
    h4 {
      color: white;
      margin: 0;
    }
  }

  &__description {
    padding: 20px;

    h2 {
      color: grey;
      margin-top: 0px;
    }
  }

  // Content
  &__content {
    //border: 1px solid rgb(44,44,44);
    overflow: hidden;
  }
}