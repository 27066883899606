// Mixin for responsive content, defines min
// and max width of the screen on which @content styles
// are applied
//
// @since 1.0.0
//
@mixin respondBetween($min: 0, $max: 0) {

  // have both
  @if $min > 0 and $max > 0 {
    @media only screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
    // have min
  @else if $min > 0 and $max <= 0 {
    @media only screen and (min-width: $min) {
      @content;
    }
  }
    // have max
  @else if $min <= 0 and $max > 0 {
    @media only screen and (max-width: $max) {
      @content;
    }
  }
}