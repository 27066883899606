@import "common/variables";
@import "common/mixins";
.contact {

  padding-bottom: 30px;

  h4 {
    margin-top: 20px;
    color: #8e8e8e;
  }
  span, p {
    color: #8e8e8e;
  }
  a {
    color: #a6a6a6;
  }
  &__employees {
    color: #8e8e8e;

    &__links {
      float: right;
    }

  }
}