@import "../common/variables";
.c-breadcrumbs {
  background-color: $color-blue;
  padding: 10px 0;

  a {
    color: white;

    &:after {
      content: ' > ';
      pointer-events: none;
    }

    &:last-child {
      &:after {
        content: '';
        pointer-events: none;
      }
    }
  }
}