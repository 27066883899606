@import "../common/variables";
$navbar-height: 89px;
$big-navbar-height: 170px;

@media (max-width: 1480px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}

.c-navbar {
    border-radius: 0;
    min-height: $navbar-height;
    background-color: white;
    margin-bottom: 0;
    position: fixed;
    width: 100%;
    border: none;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
    z-index: 10;
    font-weight: 500;

    &__logo {
        width: 85%;
    }

    &__helper {
        height: $navbar-height;
    }

    .glyphicon-search {
        color: #007864;
        top: -1px;
    }

    &__search-bar {
        position: fixed;
        width: 100%;
        top: $navbar-height;
        background-color: white;
        min-height: 50px;
        z-index: 10;
        display: none;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);

        i {
            color: #007864;
        }

        input {
            width: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            margin: 12px 0;
            color: white;
        }

        &__search-icon {
            display: inline-block;
        }

        &__form {
            display: inline-block;
            width: 85%;
        }

        &__close-search-icon {
            display: inline-block;
            float: right;
            margin: 14px 0;
            cursor: pointer;
            margin-right: 13px;
        }

        &--displayed {
            display: block;
        }
    }

    .navbar-brand {
        height: auto;
        padding: 11px 15px;
    }

    @media (min-width: 1480px){
        .navbar-nav > li > a {
            padding-top: 35px;
            padding-bottom: 34px;
        }
        #myNavbar {
            padding-top: 30px;
        }
        min-height: $big-navbar-height;
        &__helper {
            height: $big-navbar-height;
        }
        &__search-bar {
            top: $big-navbar-height;
        }

        &__search-bar {
            z-index: 9;
        }
    }
    @media (max-width: 1480px){
        // Logo
        &__logo {
            max-width: 210px;
        }
    }

    .navbar-toggle {
        margin-top: 27px;
        margin-bottom: 22px;
        border: none;

        .icon-bar {
            color: #007864;
            background-color: #007864;
        }
    }

    .social-icons {
        a {
            padding: 10px 10px;
        }

        @media (max-width: 1480px){
            li {
                display: inline !important;
                a {
                    display: inline;
                }
            }
        }
    }

    .navbar-nav > li > .dropdown-menu {
        width: 100%;
        left: 0;
        background-color: $color-blue;
    }

    .navbar-nav > li > .normal-dropdown {
        position: relative;
        background-color: $color-blue;
    }

    .dropdown-menu-box, .dropdown-menu {
        padding: 20px 0px;
        box-sizing: border-box;

        a {
            color: white;
        }

        h4 {
            color: #8e8e8e;
            margin: 0;
            margin-bottom: 20px;
        }
    }

    .nav > li {
        position: static;
        display: block;
    }

    .navbar-nav {
        display: inline-block;
        float: none;
        // padding: 10px 0px;

        @media(max-width: 1480px){
            display: block;
        }
    }

    .navbar-collapse {
        text-align: center;
        max-height: 69px;

        @media(max-width: 1480px){
            text-align: left;
            max-height: unset;
        }
    }

    &__desktop-search-btn {
        @media (max-width: 1480px){
            display: none !important;
        }
    }

    &__mobile-search-btn {
        margin-top: 16px;
        margin-bottom: 14px;
        margin-right: 0;
    }

    .navbar-nav>li>a {
        color: #007864;
    }
}

.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover {
    color: #032361;
    background-color: transparent;
}

.navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover {
    color: #032361;
    background-color: white;
}

.navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    color: #032361;
    background-color: white;
}

.navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a{
    color: #032361;
    background-color: white;;
}

.navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:active{
    color: #032361;
    background-color: white;;
}

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    color: #032361;
    background-color: white;
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
    background-color: white;
}

.dropdown.open a {
    background-color: $color-blue !important;
    color: white !important;
    border-bottom: $color-blue solid 1px !important;
}