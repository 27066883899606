.c-footer {
  background-color: #007864;
  padding: 30px 0;
  box-sizing: border-box;

  // Box
  &__box {
    @media(max-width: 768px){
      margin-bottom: 30px;
    }
    // Box title
    h3 {
      color: white;
      margin: 0;
      font-size: 20px;
      margin-bottom: 14px;
      font-weight: bold;
    }
    // Box content
    &__content {
      p {
        color: white;
        margin: 0;
        font-size: 14px;
      }
    }
  }

  // Social icons
  &__social-icons {
    margin-top: 15px;
    margin-bottom: 15px;

    // Link
    a {
      color: white;
      margin-right: 15px;
      font-size: 20px;
    }
  }

  // Footer menu
  &__menu {
    margin: 0;
    padding: 0;

    // List item
    li {
      list-style: none;
    }
  }

  // Link color
  a {
    color: white;
  }

  // Copyright
  &__copyright {
    margin-top: 20px;
    p {
      text-align: center;
      color: white;
    }
  }
}