.p-upcoming_events {
    .c-upcoming_events-item-box  {
        min-height: 410px;
    }
}


.c-upcoming_events{
    margin: 20px 0px;

    &__padding_zero{
        padding: 0px;
    }

    &__panel{
        background: #007864;
        color:white;
        text-align:center;
    }

    // Header
    &__header {
        background-color: rgb(44,44,44);
        padding: 10px 20px;
        h4 {
            color: white;
            margin: 0;
        }
    }

    &__description {
        padding: 20px;
    }

    // Content
    &__content {
        //border: 1px solid rgb(44,44,44);
        overflow: hidden;
    }

    &__listing-row {

        margin-top: 30px;

        // Image
        img {
            width: 100%;
            height: auto;
        }

        // title
        h3 {
            transition: 100ms ease all;
            font-weight: normal;
            margin-top: 20px;

            a {
                color: #333;
                text-decoration: none;
                &:hover {
                    color: #8e8e8e;
                }
            }
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        p {
            color: #333;
        }

        &__date {
            p {
                color: #8e8e8e;
            }
            a {
                color: #333;
            }
            padding-bottom: 10px;
        }

    }

    &__search-holder {
        margin-top: 30px;
    }

    &__search-button
    {
        background: #007864;
        color:white;
        border-color: white;
    }

    &__all-upcoming_events
    {
        padding-top: 10px;
        padding-bottom: 20px;
    }

    &__loading {
        opacity: 0;
        transition: 100ms ease all;
        img {
            display: block;
            margin: 0 auto;
            max-width: 40px;
        }
        &--active {
            opacity: 1;
        }
    }

    &__no-upcoming_events-message {
        display: none;
        p {
            text-align: center;
        }
        margin-bottom: 20px;

        &--shown {
            display: block;
        }
    }
}