@import "../common/mixins";
@import "../common/variables";

.slider {
  position: relative;

  .mobile-navbar-helper {
    @include respondBetween(0, $tablet-breakpoint) {
      padding-bottom: 50px;
    }
  }

  @include respondBetween(0, $ipad-breakpoint){
    position: static;
  }

  @include respondBetween(0, $ipad-breakpoint){
    background-color: $color-blue;
  }

  #heroCarousel {
    min-height: $carousel-height;

    @include respondBetween(0, $ipad-breakpoint){
      min-height: $carousel-mobile-height;
    }

    overflow: hidden;

    .item {
      min-height: $carousel-height;
      background-size: cover;
      /*height: 600px;*/
      width: 100%;
      background-position: center;

      @include respondBetween(0, $ipad-breakpoint){
        min-height: $carousel-mobile-height;
      }

      &__content {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include respondBetween(0, $ipad-breakpoint){
          top: 50%;
        }

        // Title
        h2 {
          text-align: center;
          font-size: 40px;
          color: white;
          font-weight: bold;
        }

        // Paragraph
        p {
          text-align: center;
          font-size: 16px;
          color: white;
          font-weight: bold;
        }
      }

    }


  }

  // Slider services
  .slider-services {
    position: absolute;
    z-index: $z-index-news;
    top: 71%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    @include respondBetween(0, $ipad-breakpoint){
      position: relative;
      padding: 40px 0px;
    }

    // News item
    &__item {
      overflow: hidden;


      @include respondBetween(0, $ipad-breakpoint){
        margin-bottom: 20px;
      }

      // Image
      &__image {
        border: 2px solid $color-white;
        img {
          width: 100%;
        }
      }

      // Content
      &__content {
        padding: 20px;

        h3 {
          color: $color-white;
          margin: 0 0 5px 0;
          font-size: 22px;
        }

        p {
          color: $color-white;
          margin: 0 0 10px 0;
        }

        a {
          text-transform: uppercase;
          border: 1px solid $color-white;
          color: $color-white;
          padding: 7px 10px;
          font-weight: bold;
          font-size: 12px;
          display: inline-block;
        }
      }
    }
  }

  .carousel-control {
    background-image: none !important;
  }

  .slider-services-carousel-control {
    @include respondBetween(0, $ipad-breakpoint){
      // display: none;
    }
  }
}