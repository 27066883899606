@import "common/variables";
@import "common/mixins";
.cp-homepage {

  // Content
  &__content {
    padding: 30px 0;

    // Box
    &__box {
      // Title
      h2 {
        color: $color-blue;
      }
      // Content
      &__content {
        border-top: 1px solid grey;
        padding-top: 15px;
      }
    }
  }

  // News highlights
  &__news-highlights {
    @include respondBetween(0, $tablet-breakpoint) {
      margin-bottom: 20px;
    }
    // Item
    &__item {
      margin-bottom: 20px;

      // If last child - remove margin
      &:last-child {
        margin-bottom: 0;
      }

      // Item title
      h4 {
        color: $color-blue;
      }

      a {
        color: $color-blue;
      }
    }
  }

  // Case studies
  &__case-studies {
    // Item
    &__item {
      margin-bottom: 20px;
      overflow: hidden;

      // If last child
      &:last-child {
        margin-bottom: 0;
      }

      // Item image
      &__image {
        float: left;
        width: 40%;
        @include respondBetween(0, $tablet-breakpoint) {
          width: 100%;
          float:none;
        }
        img {
          width: 100%;
          margin-bottom: 10px;
        }
      }

      // Item content
      &__content {
        float: left;
        width: 60%;
        box-sizing: border-box;
        padding-left: 10px;
        @include respondBetween(0, $tablet-breakpoint) {
          width: 100%;
          float:none;
        }
        h4 {
          color: $color-blue;
        }
      }
    }
  }

  // Sponsors
  &__sponsors {
    background-color: #000000;
    padding: 10px 0;
    color: #ffffff;
    text-align: center;
    font-size: 20px;

    // Sponsors item
    &__item {
      box-sizing: border-box;
      padding: 15px 0;
      img {
        max-width: 200px;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }
  }
}